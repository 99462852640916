import React, { useState, useCallback, useRef } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import { searchPlayers } from "../services/player.service";

const EditPlayerModal = ({
  show,
  onHide,
  editingPlayer, // Current player being replaced (if any)
  editingPosition, // Position we're editing
  POSITION_MAP,
  selectedWeek, // Current week
  getAccessTokenSilently,
  onSavePlayer,
  formatCurrency,
  roster,
  salaryCap,
}) => {
  const [modalLoading, setModalLoading] = useState(false);
  const [playerSearch, setPlayerSearch] = useState("");
  const [playerOptions, setPlayerOptions] = useState([]);
  const [showPlayerOptions, setShowPlayerOptions] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [error, setError] = useState(null);

  const playerSearchRef = useRef(null);

  // Calculate available cap space
  const calculateAvailableCap = useCallback(() => {
    const rosterTotalSalary = roster.reduce((total, player) => {
      if (editingPlayer && player.playerID === editingPlayer.playerID) {
        return total;
      }
      return total + (Number(player.salary) || 0);
    }, 0);
    return salaryCap ? Number(salaryCap) - rosterTotalSalary : 0;
  }, [roster, editingPlayer, salaryCap]);

  const handlePlayerSearch = useCallback(
    async (searchTerm) => {
      if (searchTerm.length < 2) {
        setPlayerOptions([]);
        setShowPlayerOptions(false);
        setError(null);
        return;
      }

      setModalLoading(true);
      try {
        const token = await getAccessTokenSilently();

        // Simplified search parameters
        const searchParams = {
          searchTerm,
          position: POSITION_MAP[editingPosition],
          maxSalary: calculateAvailableCap(),
          currentPlayerId: editingPlayer?.playerID,
          week: selectedWeek,
        };

        const { data, error } = await searchPlayers(token, searchParams);

        if (error) {
          throw new Error(error);
        }

        if (data.length === 0) {
          setError("No matching players found");
        } else {
          setError(null);
        }

        // Format players for display
        const formattedPlayers = data.map((player) => ({
          ...player,
          displayName: `${player.name} (${player.position} - ${player.teamAbv})`,
        }));

        setPlayerOptions(formattedPlayers);
        setShowPlayerOptions(formattedPlayers.length > 0);
      } catch (error) {
        console.error("Search error:", error);
        setError(`Failed to search players: ${error.message}`);
      } finally {
        setModalLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      editingPosition,
      POSITION_MAP,
      editingPlayer,
      selectedWeek,
      calculateAvailableCap,
    ]
  );

  const handlePlayerSelect = useCallback((player) => {
    setSelectedPlayer(player);
    setPlayerSearch(player.displayName);
    setShowPlayerOptions(false);
  }, []);

  const handleClose = useCallback(() => {
    setPlayerSearch("");
    setPlayerOptions([]);
    setShowPlayerOptions(false);
    setSelectedPlayer(null);
    setError(null);
    onHide();
  }, [onHide]);

  const handleSave = useCallback(() => {
    onSavePlayer(selectedPlayer);
    handleClose();
  }, [selectedPlayer, onSavePlayer, handleClose]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit {editingPosition} Position</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}

        {editingPlayer && (
          <div className="mb-3">
            <h6>Current Roster Player:</h6>
            <div className="player-info p-3 bg-light rounded">
              <strong>Name:</strong> {editingPlayer.player_name}
              <br />
              <strong>Team:</strong> {editingPlayer.teamAbv}
              <br />
              <strong>Position:</strong> {editingPosition}
              <br />
              <strong>Salary:</strong> {formatCurrency(editingPlayer.salary)}
            </div>
          </div>
        )}

        <div className="mb-3">
          <h6>Available Cap Space:</h6>
          <div className="cap-info p-3 bg-light rounded">
            {formatCurrency(calculateAvailableCap())}
          </div>
        </div>

        <Form.Group ref={playerSearchRef}>
          <Form.Label>
            <h6>Search for new {POSITION_MAP[editingPosition]} player:</h6>
            {modalLoading && (
              <small className="text-muted">Loading available players...</small>
            )}
          </Form.Label>
          <Form.Control
            type="text"
            value={playerSearch}
            onChange={(e) => {
              setPlayerSearch(e.target.value);
              handlePlayerSearch(e.target.value);
            }}
            placeholder={`Search ${POSITION_MAP[editingPosition]} players...`}
            disabled={modalLoading}
            required
          />
          {showPlayerOptions && (
            <ListGroup
              className="position-absolute w-100"
              style={{ zIndex: 1000 }}
            >
              {playerOptions.map((player, index) => (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={() => handlePlayerSelect(player)}
                  className="d-flex justify-content-between align-items-center"
                >
                  <span>
                    <strong>{player.name}</strong> ({player.position} -{" "}
                    {player.teamAbv})
                  </span>
                  <span className="text-success">
                    {Boolean(player.isOnRoster) && (
                      <span className="me-2 badge bg-info">
                        Current/Previous Roster
                      </span>
                    )}
                    {formatCurrency(player.salary)}
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Form.Group>

        {selectedPlayer && (
          <div className="mt-3">
            <h6>Selected Player to Add:</h6>
            <div className="player-info p-3 bg-light rounded">
              <strong>Name:</strong> {selectedPlayer.name}
              <br />
              <strong>Team:</strong> {selectedPlayer.teamAbv}
              <br />
              <strong>Position:</strong> {selectedPlayer.position}
              <br />
              <strong>Salary:</strong> {formatCurrency(selectedPlayer.salary)}{" "}
              {/* Use the passed-in function directly */}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={!selectedPlayer || modalLoading}
        >
          {modalLoading ? "Loading..." : "Confirm Roster Change"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPlayerModal;
